import { useEffect, useState } from 'react';
import { API, Auth } from 'aws-amplify';

import {
  Heading,
  Text,
  View,
  useAuthenticator,
  Loader,
  Flex
} from '@aws-amplify/ui-react';

export function UsersReport({ updateProfiles, aProfile, updateAProfile }) {

  const [isLoading, setLoading] = useState(true);
  const [marketingData, setMarketingData] = useState([]);
  const { user } = useAuthenticator((context) => [context.user]);

  async function getMarketingReport() {
    var userLocal = undefined;
    try {
      userLocal = await Auth.currentAuthenticatedUser();
    } catch (error) {
      userLocal = user;
    };
    //when just signed out both user & userLocal are undefined
    try {
      const requestReport = {
        headers: {
          Authorization: `${userLocal.signInUserSession.idToken.jwtToken}`
        }
      };
      const data = await API.get("gcAPI", `/dashboard-marketing-report`, requestReport);
      await setMarketingData(data);
      setLoading(false);
    } catch (e) {
      console.log(e);
    };
  }

  useEffect(() => {
    //call API to get marketing report data
    getMarketingReport();
    //eslint-disable-next-line
  }, [aProfile]);

  function MarketingReport() {
console.log(marketingData);
    const result = marketingData;

    function createMonthDivs() {
      const returnResponseArr = [];
      let monthName = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
      var d = new Date();
      d.setDate(1);
      for (var i = 0; i <= 5; i++) {
        returnResponseArr.push(d.getFullYear() + '-' + monthName[d.getMonth()]);
        d.setMonth(d.getMonth() - 1);
      }
      returnResponseArr.push('older');
      return returnResponseArr;
    }

    const divsArray = createMonthDivs();

    const allDashboard = (
      <Flex
        direction="row"
        justifyContent="flex-start"
        alignItems="stretch"
        alignContent="flex-start"
        wrap="nowrap"
        gap="2rem"
      >
        {divsArray.map((div, i) => (
          <View key={i} size={1}>
            {/* ACQUISITION */}
            <div>
              <b>{div}</b>
              <br /><br />

              <Flex
                direction="row"
                justifyContent="flex-start"
                alignItems="stretch"
                alignContent="flex-start"
                wrap="nowrap"
                gap="1 rem"
              >
                {result.Data[div].ACQUISITION.all}
              <Loader
                isPercentageTextHidden
                isDeterminate
                variation="linear"
                percentage={100}

                data-toggle="tooltip"
                data-placement="top"
                data-container="body"
                title="REGISTER: 100%"
                value={result.Data[div].ACQUISITION.all}
                min={0}
                max={100}
                className="progress-bar drawLostList"
                data-lost={`${div}:ACQUISITION`}
              /></Flex>
            </div>

            {/* VERIFY */}
            <div>
              <br /><br />
              <Flex
                direction="row"
                justifyContent="flex-start"
                alignItems="stretch"
                alignContent="flex-start"
                wrap="nowrap"
                gap="1 rem"
              >
                {result.Data[div].VERIFY.all}
              <Loader
                isPercentageTextHidden
                isDeterminate
                variation="linear"
                percentage={(result.Data[div].VERIFY.all/ result.Data[div].ACQUISITION.all) * 100}

                data-toggle="tooltip"
                data-placement="top"
                data-container="body"
                title={`VERIFY: ${Math.floor(Number(result.Data[div].VERIFY.all / result.Data[div].ACQUISITION.all) * 100)}%`}
                value={result.Data[div].VERIFY.all}
                min={0}
                max={result.Data[div].ACQUISITION.all}
                className="progress-bar drawLostList"
                data-lost={`${div}:VERIFY`}
              />
              </Flex>
            </div>

            {/* PROFILE_START */}
            <div>
              <br /><br />
              <Flex
                direction="row"
                justifyContent="flex-start"
                alignItems="stretch"
                alignContent="flex-start"
                wrap="nowrap"
                gap="1 rem"
              >
                {result.Data[div].PROFILE_START.all}
              <Loader
                isPercentageTextHidden
                isDeterminate
                variation="linear"
                percentage={(result.Data[div].PROFILE_START.all/ result.Data[div].VERIFY.all) * 100}

                data-toggle="tooltip"
                data-placement="top"
                data-container="body"
                title={`TRY: ${Math.floor(Number(result.Data[div].PROFILE_START.all / result.Data[div].VERIFY.all) * 100)}%`}
                value={result.Data[div].PROFILE_START.all}
                min={0}
                max={result.Data[div].VERIFY.all}
                className="progress-bar drawLostList"
                data-lost={`${div}:PROFILE_START`}
              />
              </Flex>
            </div>

            {/* PROFILE_COMPLETED */}
            <div>
              <br /><br />
              <Flex
                direction="row"
                justifyContent="flex-start"
                alignItems="stretch"
                alignContent="flex-start"
                wrap="nowrap"
                gap="1 rem"
              >
                {result.Data[div].PROFILE_COMPLETED.all}
              <Loader
                isPercentageTextHidden
                isDeterminate
                variation="linear"
                percentage={(result.Data[div].PROFILE_COMPLETED.all/ result.Data[div].PROFILE_START.all) * 100}

                data-toggle="tooltip"
                data-placement="top"
                data-container="body"
                title={`TRY: ${Math.floor(Number(result.Data[div].PROFILE_COMPLETED.all / result.Data[div].PROFILE_START.all) * 100)}%`}
                value={result.Data[div].PROFILE_COMPLETED.all}
                min={0}
                max={result.Data[div].PROFILE_START.all}
                className="progress-bar drawLostList"
                data-lost={`${div}:PROFILE_START`}
              />
              </Flex>
            </div>

            {/* TRY */}
            <div>
              <br /><br />
              <Flex
                direction="row"
                justifyContent="flex-start"
                alignItems="stretch"
                alignContent="flex-start"
                wrap="nowrap"
                gap="1 rem"
              >
                {result.Data[div].TRY.all}
              <Loader
                isPercentageTextHidden
                isDeterminate
                variation="linear"
                percentage={(result.Data[div].TRY.all/ result.Data[div].VERIFY.all) * 100}

                data-toggle="tooltip"
                data-placement="top"
                data-container="body"
                title={`TRY: ${Math.floor(Number(result.Data[div].TRY.all / result.Data[div].VERIFY.all) * 100)}%`}
                value={result.Data[div].TRY.all}
                min={0}
                max={result.Data[div].VERIFY.all}
                className="progress-bar drawLostList"
                data-lost={`${div}:TRY`}
              />
              </Flex>
            </div>

            {/* SUBSCRIBE */}
            <div>
              <br /><br />
              <Flex
                direction="row"
                justifyContent="flex-start"
                alignItems="stretch"
                alignContent="flex-start"
                wrap="nowrap"
                gap="1 rem"
              >
                {result.Data[div].SUBSCRIBE.all}
              <Loader
                isPercentageTextHidden
                isDeterminate
                variation="linear"
                percentage={(result.Data[div].SUBSCRIBE.all/ result.Data[div].TRY.all) * 100}

                data-toggle="tooltip"
                data-placement="top"
                data-container="body"
                title={`SUBSCRIBE: ${Math.floor(Number(result.Data[div].SUBSCRIBE.all / result.Data[div].TRY.all) * 100)}%`}
                value={result.Data[div].SUBSCRIBE.all}
                min={0}
                max={result.Data[div].TRY.all}
                className="progress-bar drawLostList"
                data-lost={`${div}:SUBSCRIBE`}
              />
              </Flex>
            </div>
          </View>
        ))}
      </Flex>
    );

    return allDashboard;
  }

  return (
    <View align="center">
        <Heading level={3} fontWeight={'bold'} textAlign="center">
          Users Dashboard
        </Heading>
        <View align="center">
          <Text variation="secondary">
            Search all users for fine grained analysis
          </Text>
        </View>
        <br />
        <br />
        {isLoading ? <Loader variation="linear" /> : <MarketingReport />}
    </View>
  )
}
import { useAuthenticator, 
    View,
    Grid,
    Image,
    Text} from '@aws-amplify/ui-react';
import { Balance } from "./Balance";


export function UserName ({balance, style}) {
    const { user } = useAuthenticator((context) => [context.user]);
    return (
      <Grid
      columnGap="0.5rem"
      rowGap="0.5rem"
      templateColumns="50px 1fr 60px"
      templateRows="min-content"
      textAlign="left"
      alignItems="center"
      className='profile-button'
      >
           <Image
            alt="Growth.Cool Icon"
            src={`/rocket-solid-${style.color}.png`}
            height="30px"
            marginLeft="6px"
            marginTop="6px"
            marginBottom="6px"
            />
            <Text isTruncated={true} color={style.color}>{user.attributes.name}</Text>
            <View>
                <Balance balance={balance}/>
            </View>
      </Grid>
    );
  };

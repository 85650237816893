import { useState, useEffect } from 'react';
import { API, Auth, Storage } from 'aws-amplify';

import {
    Heading,
    Expander,
    Button,
    Link,
    Text,
    View,
    Collection,
    Rating,
    Loader,
    ExpanderItem,
    Image,
    Flex
} from '@aws-amplify/ui-react';

import { useNavigate } from "react-router-dom";
import { AddProfile } from "./AddProfile";

export function Tasks({ updateProfiles, aProfile, updateAProfile, profiles, balance, updateBalance }) {

    const navigate = useNavigate();

    let regexp = /android|iphone|kindle|ipad/i;
    let isMobileDevice = regexp.test(navigator.userAgent);

    const [isMobile] = useState(isMobileDevice);

    const [isLoading, setLoading] = useState(true);

    const [tasks, setTasks] = useState([]);

    const [doneButtons, setDoneButtons] = useState([]);

    async function getTasks(forProfile) {
        if (forProfile.profileUrl === undefined) return;
        setLoading(true);
        const userUpdate = await Auth.currentAuthenticatedUser();
        const requestTasks = {
            headers: {
                Authorization: `${userUpdate.signInUserSession.idToken.jwtToken}`
            }
        };
        const data = await API.get("gcAPI", `/get-tasks/${userUpdate.username + '_' + encodeURIComponent(forProfile.profileUrl)}/new`, requestTasks);

        setTasks(await Promise.all(data.map(async (el) => {
            el.oldAvatarUrl = el.avatarUrl;
            try {
                el.avatarUrl = await Storage.get(el.avatarUrl, {
                    level: "protected",
                    identityId: el.authorUser
                });
            } catch (error) {el.avatarUrl = 'https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y';}
            return { ...el }
        })));

        //from tasks create array of false values matching the length of tasks
        await setDoneButtons(new Array(data.length).fill(false));

        setLoading(false);
    };

    async function setTaskStage(taskItem, stage, elementId) {

        //remove parent node by id = elementId
        ((document.getElementById(elementId)).parentNode).remove();
        
        const user = await Auth.currentAuthenticatedUser();
        const requestSetTaskStage = {
            headers: {
                Authorization: `${user.signInUserSession.idToken.jwtToken}`
            },
            body: {
                taskItem: taskItem,
                newStage: stage
            }
        };

        try {
            const data = await API.post("gcAPI", `/update-task`, requestSetTaskStage);
            if (data.res === "success") {
                //show MORE TASKS if all done/skipped
            } else {
                console.log(data);
            };
        } catch (e) {
            console.log(e)
        };

        if (document.querySelectorAll('div.amplify-expander').length === 0) await getTasks(aProfile);
    };

    useEffect(() => {
        if ((Object.keys(aProfile).length === 0) &&
            (profiles.length > 0)) updateAProfile(profiles[0]);
        if ((tasks.length === 0) && (aProfile!==undefined)) getTasks(aProfile);
            // eslint-disable-next-line
    }, [profiles, updateAProfile, aProfile]);
    
    return (
        <View align="center">
            {Object.keys(aProfile).length > 0 ?
                <>
                    {aProfile.validated ?
                        <View maxWidth="1000px" align="left">
                            <Heading level={3} fontWeight={'bold'} textAlign="center" color={"#414c5a"}>
                                Tasks
                            </Heading>
                            <View align="center">
                                <Text variation="secondary">
                                    <br />
                                    Complete tasks to earn likes and comments for your future campaigns!
                                </Text>
                                <View align="center"><br />Check video guide <Link href="https://youtu.be/ygOvsL7jlcY" target="_blank">here</Link></View>
                            </View>
                            <br />
                            <br />
                            {isLoading ? <Loader variation="linear" /> : <Collection
                                direction='column'
                                type='list'
                                items={tasks}
                            >
                                {(item, index) => (
                                    <View key={index} padding="1rem">
                                        <Expander isCollapsible={true} maxWidth="vw" id={"task-" + index}>
                                            <ExpanderItem title={
                                                <Flex
                                                    wrap="nowrap"
                                                    alignItems="center"
                                                    justifyContent="flex-start"
                                                    width="100%"
                                                >
                                                    <Image
                                                        src={item.avatarUrl}
                                                        style={{ borderRadius: '50%', maxWidth: '50px', paddingTop: '0.5rem', paddingBottom: '0.5rem' }}
                                                    />
                                                    <Text as="span">
                                                        LIKE&nbsp;[{item.like}] {item.needsComment ? "COMMENT" : ""}
                                                    </Text>
                                                    <Flex
                                                        wrap="nowrap"
                                                        alignItems="center"
                                                        justifyContent="flex-end"
                                                        width="100%"
                                                    >
                                                        <Text as="span"><b>Earn</b> {item.quantity}&nbsp;<Rating
                                                            value={1}
                                                            maxValue={1}
                                                            fillColor="hsl(51, 100%, 50%)"
                                                        />
                                                        </Text>
                                                    </Flex>
                                                </Flex>
                                            }
                                                value={'initial-' + index}
                                                maxWidth="100%"
                                            >
                                                <Image
                                                    src={item.screenshot}
                                                    style={{ maxWidth: '100%' }}
                                                />
                                                <br />
                                                <Text as="span"><b>Step 1.</b> Open post URL: <Link
                                                    onClick={() => {
                                                        setDoneButtons(doneButtons.map((el, i) => {
                                                            if (i === index) return false;
                                                            return el;
                                                        }));
                                                    }}
                                                    href={isMobile ? item.postUrl.replace('https://www.linkedin.com', 'linkedin://') : item.postUrl}
                                                    target='_blank'
                                                    rel="noreferrer">OPEN</Link>
                                                </Text>
                                                <br /><br />
                                                <Text as="span"><b>Step 2.</b> Deliver LIKE by clicking: Like as [{item.like}]</Text>
                                                {item.needsComment ? <Text as="span"><br /><br /><b>Step 3.</b> Deliver COMMENT by typing: {item.comment}<br /></Text> : ""}
                                                {/*<Text as="span"><b>Stage:</b> {item.stage}</Text>*/}
                                                <br />
                                                <br />
                                                {item.needsComment ? <Text as="span"><b>Step 4.</b></Text> : <Text as="span"><b>Step 3.</b></Text>} Confirm delivery by clicking&nbsp;
                                                <Button
                                                    disabled={doneButtons[index]}
                                                    type="submit"
                                                    onClick={() => {
                                                        setTaskStage(item, "attempted", "task-" + index);
                                                        updateBalance(balance + 1)
                                                    }}
                                                    id={"task-" + index}
                                                >
                                                    DONE
                                                </Button>
                                                <Flex
                                                    wrap="nowrap"
                                                    alignItems="center"
                                                    justifyContent="flex-end"
                                                >
                                                <Button type="submit" onClick={() => setTaskStage(item, "rejected", "task-" + index)} backgroundColor="red" color="white">SKIP</Button>
                                                </Flex>
                                            </ExpanderItem>
                                        </Expander>
                                    </View>
                                )}
                            </Collection>}

                            {(tasks.length === 0 && !isLoading) ? <View textAlign="center"><br /><br /><Button onClick={() => { window.location.reload() }}>GET MORE TASKS</Button></View> : <></>}
                        </View>
                        :
                        <AddProfile updateProfiles={updateProfiles} aProfile={aProfile} updateAProfile={updateAProfile} />
                    }
                </>
                :
                <View maxWidth="1000px" align="left">
                    <Heading level={3} fontWeight={'bold'} textAlign="center">
                        Tasks
                    </Heading>
                    <View align="center">
                        <Text variation="secondary">
                            <br />
                            Start by adding social media profile to start earning from it.
                            <br />
                            We won't get any access to your profile or show it publicly.
                        </Text>
                        <br />
                        <Button variation="primary" onClick={() => { navigate("/addProfile") }}>ADD PROFILE</Button>
                    </View>
                    <br />
                    <br />
                </View>}
        </View>
    )
}
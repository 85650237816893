import "@aws-amplify/ui-react/styles.css";
import {
  Grid, useAuthenticator, View,
  Text, Image
} from '@aws-amplify/ui-react'
import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router";

export function SimpleLayout({ children }) {
  const { route } = useAuthenticator((context) => [context.route]);
  const location = useLocation();
  const navigate = useNavigate();
  let from = location.state?.from?.pathname || "/";
  useEffect(() => {
    if (route === "authenticated") {
      navigate(from, { replace: true });
    };
  }, [route, navigate, from]);

  return (
    //grid with 1 column and 2 rows
    //row 1 is the header
    //row 2 contains the children
    <Grid
      templateRows="auto 1fr"
      templateColumns="1fr"
      height="100vh"
      width="100vw"
    >
      <Grid
        columnGap="0.5rem"
        rowGap="0.5rem"
        templateColumns="1fr 50px"
        templateRows="1fr"
        z-index="2000"
        style={{ backgroundColor: "#59687c", color: "white" }}
      >
        <Grid
          columnGap="0.5rem"
          rowGap="0.5rem"
          templateColumns="60px 1fr"
          templateRows="min-content"
          textAlign="left"
          alignItems="center"
        >
          <Image
            alt="Growth.Cool Icon"
            src={`/rocket-solid-white.png`}
            height="35px"
            marginLeft="6px"
            marginTop="6px"
            marginBottom="6px"
          />

          <Text
            color={"white"}
            fontWeight={800}
            fontSize="1.8em"
          >
            Growth.Cool
          </Text>
        </Grid>
      </Grid>

      <View>
        {children}
      </View>
    </Grid>
  );
}
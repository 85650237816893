import { useEffect } from 'react';
import { API } from 'aws-amplify';

import {
  Heading,
  Text,
  View,
  useAuthenticator
} from '@aws-amplify/ui-react';

import './billing.css';

export function Billing({ clientData, updateClientData }) {

  const { user } = useAuthenticator((context) => [context.user]);
  const authToken = `${user.signInUserSession.idToken.jwtToken}`;
  //files from G.C/gc-backup/app-gc-backup-21-10-2022/js/
  useEffect(() => {
    const scripts = [
      './js/vendor/jquery-3.2.1.min.js',
      './js/jquery.payment.min.js',
      './js/vendor/bootstrap.min.js',
      './js/owl.carousel.min.js',
      './js/main.js'
    ];
  
    const loadScript = (src) => {
      return new Promise((resolve, reject) => {
        if (document.querySelector(`script[src="${src}"]`)) {
          // Script already exists in the DOM, resolve immediately
          resolve();
          return;
        }

        const script = document.createElement('script');
        script.src = src;
        script.onload = resolve;
        script.onerror = reject;
        document.body.appendChild(script);
      });
    };
    
    const loadScripts = async () => {
      try {
        for (let i = 0; i < scripts.length; i++) {
          await loadScript(scripts[i]);
        }

        (function ($) {
          var planId;
          var planTotal;

          if (clientData && (clientData.subscriptionPlan !== 'none')) {
            if (clientData && (clientData.payments === 'active')) {
                          /**/$('a#subscribe1').attr('disabled', true);
              $('a#subscribe2').attr('disabled', true);
              $('a#subscribe3').attr('disabled', true);
              $('a#subscribe4').attr('disabled', true);/**/
            } else {
                          /**/$('a#subscribe1').attr('disabled', false);
              $('a#subscribe2').attr('disabled', false);
              $('a#subscribe3').attr('disabled', false);
              $('a#subscribe4').attr('disabled', false);/**/
            };
            switch (clientData.subscriptionPlan) {
              case 'plus':
                // code
                planTotal = 20;
                $('#planName').html('Plus');
                unsubscribeButton(clientData.subscriptionPlan, 'a#subscribe3');
                break;

                case 'basic-20-25':
                  // code
                  planTotal = 20;
                  $('#planName').html('Lite');
                  unsubscribeButton(clientData.subscriptionPlan, 'a#subscribe2');
                  break;
  
                case 'standard-40-25':
                // code
                planTotal = 40;
                $('#planName').html('standard 1');
                unsubscribeButton(clientData.subscriptionPlan);
                break;

              case 'standard-20-50':
                // code
                planTotal = 20;
                $('#planName').html('Plus');
                unsubscribeButton(clientData.subscriptionPlan, 'a#subscribe3');
                break;

              case 'professional-60-25':
                // code
                planTotal = 20;
                $('#planName').html('Professional');
                unsubscribeButton(clientData.subscriptionPlan, 'a#subscribe4');
                break;

              default:
                // code
                planTotal = 0;
                $('#planName').html('none');
            };
          } else { $('#planName').html('none'); planTotal = 2; };
          if (clientData && clientData.postBalance) { $('#postBalance').html(clientData.postBalance + '/' + planTotal); }
          else { $('#postBalance').html('0/' + planTotal); };
          if (clientData && clientData.postBalance) { $('#userEmail').html(clientData.email); }
          else { $('#userEmail').html(''); };


          function isValidUSZip(sZip) {
            return /^(?:[A-Z0-9]+([- ]?[A-Z0-9]+)*)?$/.test(sZip);
          };

          function unsubscribeButton(curPlanName, selectorName) {
            if ((!$('#unsubscribeButton').length) && (clientData && (clientData.payments === 'active'))) {
              var btn = document.createElement("a");
              btn.appendChild(document.createTextNode('UNSUBSCRIBE'));
              btn.setAttribute("href", "#unsubscribeModal");
              btn.setAttribute("data-toggle", "modal");
              btn.setAttribute("type", "button");
              btn.setAttribute("id", "unsubscribeButton");
              btn.setAttribute("class", "btn bg-dark-gray shadow-md");
              $(selectorName).parent().append(btn);
              $('a#subscribe3').hide();
            } else {
              if (clientData && (clientData.payments !== 'active')) {
                $('a#unsubscribeButton').remove();
              }
            };
          };

          function handleSubscribeClick(event) {
            event.preventDefault();
            planId = $(this).attr('data-plan');
            if (clientData && (clientData.payments !== 'active')) {
              var planDesc = {

                'plus': 'Growth.Cool Plus<br> $8.90 per month',
                'basic-20-25': 'Growth.Cool Lite<br> $25 per month',
                'standard-40-25': '<i><b>Standard 1</b></i> (89$/month)<br> 40 posts/month and 25 likes/post',
                'standard-20-50': 'Growth.Cool Plus<br> $49 per month',
                'professional-60-25': 'Growth.Cool Professional<br> $89 per month'
              };
              $('#planInfoPlace').html(planDesc[planId]);
              $('#paymentModal').modal('show')
            };// else if ( typeof(clientData) == "undefined") document.location.reload();
          }

          async function handleUnsubscribeConfirmedClick(event) {
            event.preventDefault();
            //start spinner and make submit disabled
            document.getElementById("overlay-processingU").style.display = "block";
            var unsubscribeParams = {
              headers: {
                  Authorization: `${authToken}`
              },
              contentType: 'application/json',
              body: clientData
            };
            try {
              const dataUnsubscribe = await API.post("gcAPI", `/make-unsubscribe`, unsubscribeParams);
              console.log(dataUnsubscribe);
              document.getElementById("overlay-processingU").style.display = "none";
              if (dataUnsubscribe.body.sub && (dataUnsubscribe.body.sub === 'cancelled')) {
                document.getElementById("overlay-successU").style.display = "block";
                let newClientData = JSON.parse(JSON.stringify(clientData));
                newClientData.payments = 'inactive';
                newClientData.subscriptionPlan = 'none';
                updateClientData(newClientData);
                $('a#subscribe3').show();
                //remove unsubscribe button node a#unsubscribeButton
                $('a#unsubscribeButton').remove();
              } else {
                $('#err-textU').html(dataUnsubscribe.body.dbu.message);
                //				console.log('error', result.body.dbu.message);
                document.getElementById("overlay-errorU").style.display = "block";
              };

              //dataUnsubscribe should contain sub object with new user data
              //so updateClientData(dataUnsubscribe.body. ... something)); should be here
              //so the page will show unsubscribe button on recently paid plan


            } catch (e) {
              document.getElementById("overlay-processingU").style.display = "none";
              document.getElementById("overlay-errorU").style.display = "block";
            };

          }

          $('#subscribe1').click(handleSubscribeClick);
          $('#subscribe2').click(handleSubscribeClick);
          $('#subscribe3').click(handleSubscribeClick);
          $('#subscribe4').click(handleSubscribeClick);
          $('#unsubscribeConfirmed').click(handleUnsubscribeConfirmedClick);

          $('#paymentModal').on('hidden.bs.modal', function (e) {
            document.getElementById("overlay-processing").style.display = "none";
            document.getElementById("overlay-success").style.display = "none";
            document.getElementById("overlay-error").style.display = "none";

            $('.cc-number').toggleClass('CheckoutInput--invalid', false);
            $('.cc-exp').toggleClass('CheckoutInput--invalid', false);
            $('.cc-cvc').toggleClass('CheckoutInput--invalid', false);
            $('.cc-zip').toggleClass('CheckoutInput--invalid', false);

          });

          $('#unsubscribeModal').on('hidden.bs.modal', function (e) {
            document.getElementById("overlay-processingU").style.display = "none";
            document.getElementById("overlay-successU").style.display = "none";
            document.getElementById("overlay-errorU").style.display = "none";
          });

          //start stripe validation
          $('[data-numeric]').payment('restrictNumeric');
          $('.cc-number').payment('formatCardNumber');
          $('.cc-exp').payment('formatCardExpiry');
          $('.cc-cvc').payment('formatCardCVC');
          $.fn.toggleInputError = function (erred) {
            this.toggleClass('CheckoutInput--invalid', erred);
            return this;
          };

          $('form').submit(async function (e) {
            e.preventDefault();
            var cardType = $.payment.cardType($('.cc-number').val());

            $('.cc-number').toggleInputError(!$.payment.validateCardNumber($('.cc-number').val()));
            $('.cc-exp').toggleInputError(!$.payment.validateCardExpiry($('.cc-exp').payment('cardExpiryVal')));
            $('.cc-cvc').toggleInputError(!$.payment.validateCardCVC($('.cc-cvc').val(), cardType));
            $('.cc-zip').toggleInputError(!isValidUSZip($('.cc-zip').val().toUpperCase()));

            if (!$('.CheckoutInput--invalid').length) {
              //start spinner and make submit disabled
              document.getElementById("overlay-processing").style.display = "block";

              var ccExp = $('.cc-exp').val().split(' / ');

              var paramsPayment = {
                number: $('.cc-number').val(),
                cvc: $('.cc-cvc').val(),
                expm: ccExp[0],
                expy: ccExp[1],
                zip: $('.cc-zip').val().toUpperCase()
              };

              var couponVal = $('#coupon').val();
              if (!couponVal || (couponVal.length > 30)) couponVal = '';
              //forever 99.99% off coupon
              couponVal = "01EsOWz6";

              //get firstpromoter cookie to send to make-subscription lambda
              var visitorTrackingId = document.cookie.match(new RegExp(`(^| )_fprom_track=([^;]+)`));
              if (visitorTrackingId) {
                visitorTrackingId = visitorTrackingId[2];
              } else { visitorTrackingId = '' };

              //get _ga cookie to send to make-subscription lambda
              var clientTrackingId = document.cookie.match(new RegExp(`(^| )_ga=([^;]+)`));
              if (clientTrackingId) {
                clientTrackingId = clientTrackingId[2].split('.'); clientTrackingId = clientTrackingId[2] + '.' + clientTrackingId[3];
              } else { clientTrackingId = '' };

              var subscribeParams = {
                  headers: {
                      Authorization: `${authToken}`
                  },
                  contentType: 'application/json',
                  body: { params: paramsPayment, email: clientData.email, planName: planId, coupon: couponVal, tid: visitorTrackingId, cid: clientTrackingId },

              };

              console.log("subscribeParams ", subscribeParams);
              try {
                const dataSubscribe = await API.post("gcAPI", `/make-subscription`, subscribeParams);
                console.log("dataSubscribe ", dataSubscribe);
                document.getElementById("overlay-processing").style.display = "none";
                if (dataSubscribe.body.sub && (dataSubscribe.body.sub.status === 'active')) {
                  document.getElementById("overlay-success").style.display = "block";
                } else {
                  $('#err-text').html(dataSubscribe.body.dbu.message);
                  //console.log('error', result.body.dbu.message);
                  document.getElementById("overlay-error").style.display = "block";
                };

                //dataSubscribe should contain sub object with new user data
                //so updateClientData(dataSubscribe.body. ... something)); should be here
                //so the page will show unsubscribe button on recently paid plan

              } catch (e) {
                console.log(e);
                //may be due to two cases:
                //- subscription success - this result is for waiting end
                //- 30 sec wait ended - no result yet
                //- some other error

                document.getElementById("overlay-processing").style.display = "none";
                document.getElementById("overlay-error").style.display = "block";
              };

            };
          });

        }(window.jQuery));


      } catch (error) {
        console.error('Error loading script:', error);
      }
    };
  
    loadScripts();
  
    return () => {
      scripts.forEach((src) => {
        const script = document.querySelector(`script[src="${src}"]`);
        if (script) {
          document.body.removeChild(script);
        }
      });
    };
    //eslint-disable-next-line
  }, [authToken, clientData]);

  return (
    <View align="center">
      <Heading level={3} fontWeight={'bold'} textAlign="center">
        Billing
      </Heading>
      <View align="center">
        <Text variation="secondary">
          Select and update your monthly subscription
        </Text>
      </View>

      <div id="pricing" className="section">
        <div className="container">
          <div className="ui-pricing-cards owl-carousel owl-theme">
            <div className="ui-pricing-card animate" data-show="fade-in-left">
              <div className="ui-card ui-curve shadow-lg">
                <div className="card-header bg-dark-gray">
                  <h4 className="heading">FREE</h4>
                  <div className="price text-red">
                    <span className="curency">$</span>
                    <span className="price">0</span>
                    <span className="period">/mo</span>
                  </div>
                </div>
                <div className="card-body">
                  <h6 className="sub-heading"><br />
                  </h6>
                  <ul>
                    <li>
                      <u>Unlimited</u>
                    </li>
                    <li>
                      Posts, likes & comments
                    </li>
                    <li>
                    </li>
                    <li>
                    </li>
                    <li>

                    </li>
                  </ul>
                  {/*<Button href="#paymentModal" target="_blank" className="btn shadow-md bg-dark-gray" id="subscribe2" data-plan="basic-20-25" data-toggle="modal" onClick={(e) => { console.log(e); setShowModal(true); }}>Get started</Button>*/}
                  {
                    (clientData && clientData.subscriptionPlan && (clientData.subscriptionPlan === 'none')) ?
                      <h3>CURRENT PLAN</h3>
                    :
                      <></>
                      /*
                      <a href="#paymentModal" target="_blank" className="btn shadow-md bg-dark-gray" id="subscribe2" data-plan="none" data-toggle="modal">Get started</a>
                      */
                  }                  
                  <br /><br />
                </div>
              </div>
            </div>
            <div className="ui-pricing-card animate" data-show="fade-in">
              <div className="ui-card ui-curve shadow-xl">
                <div className="card-header ui-gradient-peach">
                  <h4 className="heading">PLUS</h4>
                  <div className="price">
                    <span className="curency">$</span>
                    <span className="price">8.90</span>
                    <span className="period">/mo</span>
                  </div>
                </div>
                <div className="card-body">
                  <h6 className="sub-heading"><br />
                  </h6>
                  <ul>
                    <li>
                      All FREE features
                    </li>
                    <li>
                      PLUS
                    </li>
                    <li>
                      Audience Filters by
                    </li>
                    <li>
                      location, industry, etc.
                    </li>
                    <li>
                    </li>
                  </ul>
                  {
                    (clientData && clientData.subscriptionPlan && (clientData.subscriptionPlan === 'plus')) ?
                      <h3>CURRENT PLAN</h3>
                    :
                    <></>
                  }
                  <a href="#paymentModal" className="btn ui-gradient-peach shadow-md" id="subscribe3" data-plan="plus" data-toggle="modal">Get started</a>
                  <br /><br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="paymentModal" className="modal fade" tabIndex="-1" role="dialog" aria-labelledby="modalToken">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">

              <div className="row">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>

                <div className="col-xs-12 col-md-8 offset-md-2 col-lg-8 offset-lg-2" id="coverWoverlay">
                  <p>&nbsp;</p>
                  <h3 className="modal-title text-center" id="planInfoPlace">
                  </h3>
                  <p>&nbsp;</p>

                  <form noValidate autoComplete="on" method="POST">

                    <div className="PaymentForm-paymentMethodFormContainer flex-container spacing-16 direction-row wrap-wrap">
                      <div className="flex-item width-12">
                        <div className="FormFieldGroup">
                          <div className="FormFieldGroup-labelContainer flex-container justify-content-space-between"><label htmlFor="cardNumber-fieldset"><span className="Text Text-color--gray600 Text-fontSize--13 Text-fontWeight--500">Card information</span></label></div>
                          <div className="FormFieldGroup-Fieldset" id="cardNumber-fieldset">
                            <div className="FormFieldGroup-container" id="cardNumber-fieldset">
                              <div className="FormFieldGroup-child FormFieldGroup-child--width-12 FormFieldGroup-childLeft FormFieldGroup-childRight FormFieldGroup-childTop">
                                <div className="FormFieldInput">
                                  <span className="InputContainer" data-max=""><input className="CheckoutInput CheckoutInput--tabularnums Input Input--empty cc-number" autoComplete="cc-number" autoCorrect="off" spellCheck="false" id="cardNumber" name="cardNumber" type="text" inputMode="numeric" aria-label="Card number" placeholder="1234 1234 1234 1234" aria-invalid="false" defaultValue="" /></span>
                                  <div className="FormFieldInput-Icons" style={{ opacity: "1" }}>
                                    <div style={{ transform: "none" }}><span className="FormFieldInput-IconsIcon is-visible"><img src="img/visa-365725566f9578a9589553aa9296d178.svg" alt="visa" className="BrandIcon" /></span></div>
                                    <div style={{ transform: "none" }}><span className="FormFieldInput-IconsIcon is-visible"><img src="img/mastercard-4d8844094130711885b5e41b28c9848f.svg" alt="mastercard" className="BrandIcon" /></span></div>
                                    {<div style={{ transform: "none" }}><span className="FormFieldInput-IconsIcon is-visible"><img src="img/amex-a49b82f46c5cd6a96a6e418a6ca1717c.svg" alt="amex" className="BrandIcon" /></span></div>}
                                    <div className="CardFormFieldGroupIconOverflow">
                                      <span className="CardFormFieldGroupIconOverflow-Item CardFormFieldGroupIconOverflow-Item--invisible" role="presentation">
                                        <span className="FormFieldInput-IconsIcon" role="presentation">
                                          <img src="img/discover-ac52cd46f89fa40a29a0bfb954e33173.svg" alt="discover" className="BrandIcon" />
                                        </span>
                                      </span>
                                      <span className="CardFormFieldGroupIconOverflow-Item CardFormFieldGroupIconOverflow-Item--visible" role="presentation">
                                        <span className="FormFieldInput-IconsIcon" role="presentation">
                                          <img src="img/jcb-271fd06e6e7a2c52692ffa91a95fb64f.svg" alt="jcb" className="BrandIcon" />
                                        </span>
                                      </span>
                                      <span className="CardFormFieldGroupIconOverflow-Item CardFormFieldGroupIconOverflow-Item--invisible" role="presentation">
                                        <span className="FormFieldInput-IconsIcon" role="presentation">
                                          <img src="img/diners-fbcbd3360f8e3f629cdaa80e93abdb8b.svg" alt="diners" className="BrandIcon" />
                                        </span>
                                      </span>
                                      <span className="CardFormFieldGroupIconOverflow-Item CardFormFieldGroupIconOverflow-Item--invisible" role="presentation">
                                        <span className="FormFieldInput-IconsIcon" role="presentation">
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="FormFieldGroup-child FormFieldGroup-child--width-6 FormFieldGroup-childLeft FormFieldGroup-childBottom">
                                <div className="FormFieldInput"><span className="InputContainer" data-max=""><input className="CheckoutInput CheckoutInput--tabularnums Input Input--empty cc-exp" autoComplete="cc-exp" autoCorrect="off" spellCheck="false" id="cardExpiry" name="cardExpiry" type="text" inputMode="numeric" aria-label="Expiration" placeholder="MM / YY" aria-invalid="false" defaultValue="" /></span></div>
                              </div>
                              <div className="FormFieldGroup-child FormFieldGroup-child--width-6 FormFieldGroup-childRight FormFieldGroup-childBottom">
                                <div className="FormFieldInput has-icon">
                                  <span className="InputContainer" data-max=""><input className="CheckoutInput CheckoutInput--tabularnums Input Input--empty cc-cvc" autoComplete="cc-csc" autoCorrect="off" spellCheck="false" id="cardCvc" name="cardCvc" type="text" inputMode="numeric" aria-label="CVC" placeholder="CVC" aria-invalid="false" defaultValue="" /></span>
                                  <div className="FormFieldInput-Icon is-loaded">
                                    <svg className="Icon Icon--md" focusable="false" viewBox="0 0 32 21">
                                      <g fill="none" fillRule="evenodd">
                                        <g className="Icon-fill">
                                          <g transform="translate(0 2)">
                                            <path d="M21.68 0H2c-.92 0-2 1.06-2 2v15c0 .94 1.08 2 2 2h25c.92 0 2-1.06 2-2V9.47a5.98 5.98 0 0 1-3 1.45V11c0 .66-.36 1-1 1H3c-.64 0-1-.34-1-1v-1c0-.66.36-1 1-1h17.53a5.98 5.98 0 0 1 1.15-9z" opacity=".2"></path>
                                            <path d="M19.34 3H0v3h19.08a6.04 6.04 0 0 1 .26-3z" opacity=".3"></path>
                                          </g>
                                          <g transform="translate(18)">
                                            <path d="M7 14A7 7 0 1 1 7 0a7 7 0 0 1 0 14zM4.22 4.1h-.79l-1.93.98v1l1.53-.8V9.9h1.2V4.1zm2.3.8c.57 0 .97.32.97.78 0 .5-.47.85-1.15.85h-.3v.85h.36c.72 0 1.21.36 1.21.88 0 .5-.48.84-1.16.84-.5 0-1-.16-1.52-.47v1c.56.24 1.12.37 1.67.37 1.31 0 2.21-.67 2.21-1.64 0-.68-.42-1.23-1.12-1.45.6-.2.99-.73.99-1.33C8.68 4.64 7.85 4 6.65 4a4 4 0 0 0-1.57.34v.98c.48-.27.97-.42 1.44-.42zm4.32 2.18c.73 0 1.24.43 1.24.99 0 .59-.51 1-1.24 1-.44 0-.9-.14-1.37-.43v1.03c.49.22.99.33 1.48.33.26 0 .5-.04.73-.1.52-.85.82-1.83.82-2.88l-.02-.42a2.3 2.3 0 0 0-1.23-.32c-.18 0-.37.01-.57.04v-1.3h1.44a5.62 5.62 0 0 0-.46-.92H9.64v3.15c.4-.1.8-.17 1.2-.17z"></path>
                                          </g>
                                        </g>
                                      </g>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                              <div style={{ opacity: "0", height: "0px" }}><span className="FieldError Text Text-color--red Text-fontSize--13"><span aria-hidden="true"></span></span></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="billing-container flex-item width-12" aria-hidden="false">
                        <div style={{ pointerEvents: "auto", height: "auto", opacity: "1" }}>
                          <div className="flex-container spacing-16 direction-row wrap-wrap">
                            <div className="flex-item width-12">
                              <div className="FormFieldGroup">
                                <div className="FormFieldGroup-labelContainer flex-container justify-content-space-between"><label htmlFor="country-fieldset"><span className="Text Text-color--gray600 Text-fontSize--13 Text-fontWeight--500">Postal code</span></label></div>
                                <fieldset className="FormFieldGroup-Fieldset" id="country-fieldset">
                                  <div className="FormFieldGroup-container FormFieldGroup-container--supportTransitions" id="country-fieldset">
                                    <div className="FormFieldGroup-child FormFieldGroup-child--width-12 FormFieldGroup-childLeft FormFieldGroup-childRight FormFieldGroup-childBottom" style={{ height: "38px", transform: "none" }}>
                                      <div className="FormFieldInput"><span className="InputContainer" data-max=""><input className="CheckoutInput CheckoutInput--tabularnums Input Input--empty cc-zip" autoComplete="billing postal-code" autoCorrect="off" spellCheck="false" id="billingPostalCode" name="billingPostalCode" type="text" inputMode="numeric" aria-label="ZIP" placeholder="ZIP" aria-invalid="false" defaultValue="" /></span></div>
                                    </div>
                                    <div style={{ opacity: "0", height: "0px" }}><span className="FieldError Text Text-color--red Text-fontSize--13"><span aria-hidden="true"></span></span></div>
                                  </div>
                                </fieldset>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex-item width-12"></div>
                      <div className="flex-item width-12">
                        <button className="SubmitButton SubmitButton--incomplete" type="submit" style={{ backgroundColor: "#343145", color: "#ffffff" }}>
                          <div className="SubmitButton-Shimmer" style={{ background: "linear-gradient(to right, rgba(52, 49, 69, 0) 0%, rgb(72, 69, 90) 50%, rgba(52, 49, 69, 0) 100%)" }}></div>
                          <div className="SubmitButton-TextContainer"><span className="SubmitButton-Text SubmitButton-Text--current Text Text-color--default Text-fontWeight--500 Text--truncate" aria-hidden="false">Subscribe</span><span className="SubmitButton-Text SubmitButton-Text--pre Text Text-color--default Text-fontWeight--500 Text--truncate" aria-hidden="true">Processing...</span></div>
                          <div className="SubmitButton-IconContainer">
                            <div className="SubmitButton-Icon SubmitButton-Icon--pre">
                              <div className="Icon Icon--md Icon--square">
                                <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false">
                                  <path d="M3 7V5a5 5 0 1 1 10 0v2h.5a1 1 0 0 1 1 1v6a2 2 0 0 1-2 2h-9a2 2 0 0 1-2-2V8a1 1 0 0 1 1-1zm5 2.5a1 1 0 0 0-1 1v2a1 1 0 0 0 2 0v-2a1 1 0 0 0-1-1zM11 7V5a3 3 0 1 0-6 0v2z" fill="#ffffff" fillRule="evenodd"></path>
                                </svg>
                              </div>
                            </div>
                            <div className="SubmitButton-Icon SubmitButton-SpinnerIcon SubmitButton-Icon--pre">
                              <div className="Icon Icon--md Icon--square">
                                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" focusable="false">
                                  <ellipse cx="12" cy="12" rx="10" ry="10" style={{ stroke: "rgb(255, 255, 255)" }}></ellipse>
                                </svg>
                              </div>
                            </div>
                          </div>
                          <div className="SubmitButton-CheckmarkIcon">
                            <div className="Icon Icon--md">
                              <svg xmlns="http://www.w3.org/2000/svg" width="22" height="14" focusable="false">
                                <path d="M 0.5 6 L 8 13.5 L 21.5 0" fill="transparent" strokeWidth="2" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round"></path>
                              </svg>
                            </div>
                          </div>
                        </button>
                        <div className="ConfirmPayment-PostSubmit">
                          <div></div>
                        </div>
                      </div>
                    </div>

                  </form>

                  <p className="text-center" style={{ fontSize: "smaller" }}>By confirming your subscription, you allow Growth.Cool to charge your card for this payment and future payments in accordance with their terms.</p>
                  <p>&nbsp;</p>
                  <p className="text-center" style={{ fontSize: "smaller" }}><a href="https://growth.cool/terms/" target="_blank" rel="noreferrer"><u style={{ color: "#708198" }}>Terms</u></a> | <a href="https://growth.cool/privacy/" target="_blank" rel="noreferrer"><u style={{ color: "#708198" }}>Privacy</u></a></p>


                  <div id="overlay-processing">
                    <div id="text-processing"><i className="fa fa-cog fa-spin"></i><br />Processing</div>
                  </div>

                  <div id="overlay-success">
                    <div id="text-processing">Success!</div>
                    <div id="text-processing2"><br /><br /><br /><br /><br /></div>
                  </div>

                  <div id="overlay-error">
                    <div id="text-processing">Error</div>
                    <div id="text-processing2" style={{ width: "68%" }}>
                      <br /><br /><br /><br /><div id="err-text"></div><br />Try again, please!<br /><br />
                      <b>For all non-US customers:</b><br />
                      <font>ask for payment link at info@growth.cool</font>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="unsubscribeModal" className="modal fade" tabIndex="-1" role="dialog" aria-labelledby="modalTokenU">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
              <div className="row" id="coverWoverlay">

                <div className="col-xs-12 col-md-8 offset-md-2 col-lg-8 offset-lg-2">
                  <br />
                  <h4 className="modal-title text-center" id="myModalLabel">CONFIRM CANCELLING SUBSCRIPTION:</h4>
                  <br /><br />
                </div>
                <div className="col-xs-12 col-md-8 offset-md-2 col-lg-8 offset-lg-2">
                  <b>Subscription to be cancelled immediately - you won't be charged any more.<br /><br />
                    Your current balance won't be affected and you may keep boosting posts until balance is not 0.</b>
                  <br /><br />
                  <br /><br />
                </div>
                <div className="col-xs-12 col-md-8 offset-md-2 col-lg-8 offset-lg-2 text-center">
                  <button href="#" className="btn ui-gradient-peach shadow-md" data-dismiss="modal">DISMISS</button>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <button href="#" id="unsubscribeConfirmed" className="btn bg-dark-gray shadow-md">UNSUBSCRIBE</button>
                  <br /><br />
                </div>

                <div id="overlay-processingU">
                  <div id="text-processing"><i className="fa fa-cog fa-spin"></i><br />Processing</div>
                </div>

                <div id="overlay-successU">
                  <div id="text-processing">Success!</div>
                  <div id="text-processing2"><br /><br /><br /><br /><br /></div>
                </div>

                <div id="overlay-errorU">
                  <div id="text-processing">Error</div>
                  <div id="text-processing2" style={{ width: "68%" }}>
                    <br /><br /><br /><br /><div id="err-textU" style={{ color: "red" }}></div><br />Try again, please!
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>
      </div>

    </View>
  )
}
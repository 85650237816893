import { useEffect, useState } from 'react';
import { API, Auth, Storage } from 'aws-amplify';

import { Heading, 
        TextField,
        Button,
        Text,
        View,
        Link,
        Loader} from '@aws-amplify/ui-react';

import {useNavigate} from "react-router-dom";
        
export function AddProfile({updateProfiles, aProfile, updateAProfile }) {
    const navigate = useNavigate();

    const [urlHasError, setUrlHasError] = useState(false);    

    const [newProfileStart, setNewProfileStart] = useState ({
        loading: false,
          
        response: null
    });

    useEffect(()=>{
        if (Object.keys(aProfile).length > 0) setNewProfileStart({
            loading: false,
            data: aProfile,
            ifValidate: true,
            profileUrl: aProfile.profileUrl,
            state: true,
            response: null
        });
    }, [aProfile]);

    async function addNewProfile(ifValidate) {

        if ((document.getElementById("profileUrl").value.indexOf("linkedin.com") < 0) &&
            (document.getElementById("profileUrl").value.indexOf("lnkd.in") < 0)) {
            setUrlHasError(true);
        } else {
            setUrlHasError(false);
            setNewProfileStart({
                loading: true,
                state: ifValidate
            });
            //call api to check URL, make & return screenshot
            //we expect profile URL error OR
            //returned screenshot to show and ask for text-code in Headline
            const user = await Auth.currentAuthenticatedUser();
            var requestStartNewCampaing = {
                headers: {
                    Authorization: `${user.signInUserSession.idToken.jwtToken}`
                },
                body: {
                    profileUrl: document.getElementById("profileUrl").value,
                    ifValidate: ifValidate,
                    ifRetry: false
                }
            };
            try {
                //setUrlHasError(false);
                var data = await API.post("gcAPI", `/post-addProfile/${user.username}`, requestStartNewCampaing);
                if (data.profiles) {
                    var sortedProfiles = data.profiles.sort((a, b) => Number(b.validated) - Number(a.validated));
                    updateProfiles(await Promise.all(sortedProfiles.map(async (el) =>{
                    el.avatarUrl = await Storage.get(el.avatarUrl, {
                      level: "protected",
                      identityId: user.username
                    })
                    return { ...el}
                  })));
                };
                if (Object.keys(aProfile).length > 0) {
                    aProfile.validated=data.data.validated;
                    updateAProfile(aProfile);
                };
                setUrlHasError(!data.state);
                setNewProfileStart(data);
            } catch (e) {
                try {
                    requestStartNewCampaing.body.ifRetry = true;
                    data = await API.post("gcAPI", `/post-addProfile/${user.username}`, requestStartNewCampaing);
                } catch (error) {
                    //console.log(e);
                    setUrlHasError(true);
                    setNewProfileStart({
                        loading: false,
                        state: false,
                        response: e.message
                    });                        
                }
                if (data && data.profiles) {
                    sortedProfiles = data.profiles.sort((a, b) => Number(b.validated) - Number(a.validated));
                    updateProfiles(await Promise.all(sortedProfiles.map(async (el) =>{
                    el.avatarUrl = await Storage.get(el.avatarUrl, {
                      level: "protected",
                      identityId: user.username
                    })
                    return { ...el}
                  })));
                };
                if (Object.keys(aProfile).length > 0) {
                    aProfile.validated=data.data.validated;
                    updateAProfile(aProfile);
                };
                setUrlHasError(!data.state);
                setNewProfileStart(data);
            };

        };

    }

    return (
            <View align="center">
                <View maxWidth="1000px" align="left">
                    <Heading level={3} fontWeight={'bold'} textAlign="center">
                    {Object.keys(aProfile).length > 0 ?
                        <>Complete Profile Validation</>
                        :
                        <>Add Profile</>
                    }
                    </Heading>
                    <View align="center">
                        <Text variation="secondary">
                            <br/>
                            Validate LinkedIn profile ownership.
                            <br/>
                            {//We won't get any access to it or show it publicly.
                            }
                       </Text>
                    </View>
                    <br/>
                    <br/>
                    <b>Step 1.</b> Paste profile URL here:
                    {Object.keys(aProfile).length > 0 ? 
                    <TextField
                        id="profileUrl"
                        hasError={urlHasError}
                        disabled={newProfileStart.state}
                        errorMessage="please fill in correct LinkedIn profile URL"
                        placeholder="e.g. https://www.linkedin.com/in/YourName ..."
                        value={aProfile.profileUrl}
                    />
                    : 
                    <TextField
                        id="profileUrl"
                        hasError={urlHasError}
                        disabled={newProfileStart.state}
                        errorMessage="please fill in correct LinkedIn profile URL"
                        placeholder="e.g. https://www.linkedin.com/in/YourName ..."
                    />
                    }
                    <br/>
                    {newProfileStart.loading ? <Loader variation="linear" /> : <>
                    {(newProfileStart.profileUrl && newProfileStart.data && !newProfileStart.data.validated) ? <>
                        <b>Step 2.</b> Copy-paste code text to anywhere in your profile Headline:
                        <br />
                        <br />
                        <Heading level={4} fontWeight={'bold'} textAlign="center">
                            {newProfileStart.data.profileId}
                        </Heading>
                        <br /><View align="center">
                        <b>Your headline for example:</b> {newProfileStart.data.headline}&nbsp;<b>{newProfileStart.data.profileId}</b>
                        {(!newProfileStart.data.validated && newProfileStart.ifValidate) ? <Text color="red">Can't find that code text in your profile headline {newProfileStart.response}</Text>:<></>}
                        <br /><br />You cand delete that code from your headline after validation is confirmed.
                        </View>
                        <br />
                        <b>Step 3.</b> Validate code presence
                        <br />
                        <br />
                        <View align="center">
                            {!newProfileStart.loading ? <Button variation={newProfileStart.loading ? "disabled" : "primary"} type="submit" onClick={() => addNewProfile(true)}>{newProfileStart.loading ? <><Loader />&nbsp;PROCESSING...</> : "VALIDATE"}</Button> : <></>}
                        </View>
                    </>
                        : <View align="center">
                            {(newProfileStart.data && newProfileStart.data.validated) ? <>
                                <Heading level={2}>Congrats!</Heading>
                                <Heading level={4}>Your profile control is validated!</Heading>
                                <Text variation="secondary">
                                    <br />
                                    You now safe to delete that code from your profile Headline.
                                </Text>
                                <br />
                                <Button variation={"primary"} type="submit" onClick={async () => {
                                    const user = await Auth.currentAuthenticatedUser();
                                    newProfileStart.data.avatarUrl = await Storage.get(newProfileStart.data.avatarUrl, {
                                        level: "protected",
                                        identityId: user.username
                                    });
                                    updateAProfile(newProfileStart.data); 
                                    navigate("/tasks");}}>Start with TASKS</Button>    
                            </> : <>
                            <Button variation="primary" type="submit" onClick={() => addNewProfile(false)}>{newProfileStart.loading ? <><Loader />&nbsp;PROCESSING...</> : "ADD PROFILE"}</Button>
                            </>}
                        </View>
                    }
                </>}
                <View align="center"><br />Check video guide <Link href="https://youtu.be/LhTSRBBlIf0" target="_blank">here</Link></View>
                </View>
            </View>
    )
}
import { Rating } from '@aws-amplify/ui-react';

export function Balance ({balance}) {
    return (
        <>
            {balance}&nbsp;<Rating
            value={1}
            maxValue={1}
            fillColor="hsl(51, 100%, 50%)"
            />            
        </>
    )
}
/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "gcAPI",
            "endpoint": "https://oxi3brgtcd.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:ecaad4b9-3fb2-4ebf-b7c5-4a7f771b070c",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_ErzzD2Pqm",
    "aws_user_pools_web_client_id": "7lehc70849mmonqbfbbuqb02do",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 6,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "bot-profiles",
            "region": "us-east-1"
        },
        {
            "tableName": "clients",
            "region": "us-east-1"
        },
        {
            "tableName": "gcDB-dev",
            "region": "us-east-1"
        }
    ],
    "aws_user_files_s3_bucket": "gcscreenshots105837-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;

import { useState } from 'react';
import { API, Auth } from 'aws-amplify';

import {
    Heading,
    TextField,
    SelectField,
    Button,
    View,
    Rating,
    StepperField,
    Loader,
    Link,
    Flex
} from '@aws-amplify/ui-react';

import Modal, { ModalBody, ModalFooter, ModalHeader } from './modal/Modal';

import { useNavigate } from "react-router-dom";

function Countdown() {

    //make animated loader in form of digital clock countdown 
    //starting from 1:00,0
    //and going down 1 tenth of second per step
    //when it reaches 0:00,0 continue with negative numbers

    var time = 60 * 10;  // start from 1:00:0
    var timerId = setInterval(countdown, 100);  // update every 0.1 second

    function countdown() {
        // Display the timer
        var minutes = Math.floor(time / 600);
        var seconds = Math.floor((time % 600) / 10);
        var tenths = time % 10;
        if (document.getElementById("timer") !== null) document.getElementById("timer").innerHTML = minutes + ":" + seconds + ":" + tenths;

        // Stop the countdown when it reaches 0:00:0
        if (time === 0) {
            clearInterval(timerId);
        }

        time--;
    }

    return (
        <div id="timer"></div>
    )
}

export function NewCampaign({ balance, updateBalance }) {

    const navigate = useNavigate();
    const [budget, setBudget] = useState(5);
    const handleOnStepChange = (newValue) => { setBudget(newValue); };

    const [showModal, setShowModal] = useState(false);

    const [urlHasError, setUrlHasError] = useState(false);

    const [newCampaignStart, setNewCampaignStart] = useState({
        loading: false,
        state: null,
        response: null
    });

    async function startNewCampaing() {

        setNewCampaignStart({
            loading: true
        });

        if ((document.getElementById("postUrl").value.indexOf("linkedin.com") < 0) &&
            (document.getElementById("postUrl").value.indexOf("lnkd.in") < 0)) {
            setUrlHasError(true);
            setNewCampaignStart({
                loading: false
            });
        } else {
            setUrlHasError(false);
            setShowModal(true);
            let regexp = /android|iphone|kindle|ipad/i;
            let isMobileDevice = regexp.test(navigator.userAgent);
            const user = await Auth.currentAuthenticatedUser();
            var requestStartNewCampaing = {
                headers: {
                    Authorization: `${user.signInUserSession.idToken.jwtToken}`
                },
                body: {
                    campaignBudget: budget,
                    postUrl: document.getElementById("postUrl").value,
                    actityType: document.getElementById("activityType").value,
                    deviceMobile: isMobileDevice,
                    ifRetry: false
                }
            };

            //call gcAPI until it returns success and make every next call after 1st
            //with ifRetry=true
            //if it returns error after 3 tries, show error message
            //if it returns success, update balance and show success message
            try {
                const data = await API.post("gcAPI", `/post-newCampaign`, requestStartNewCampaing);
                console.log(data);
                if (data.state) {
                    updateBalance(balance - budget);
                    document.getElementById("postUrl").value = '';
                    document.getElementById("budget-stepper").value = 5;
                    setBudget(5);
                }
                if (data.response.indexOf('Not enough STARS left. Please go to TASKS to earn STARS') >= 0) { } else {
                    data.response = "Your request was processed successfully! Check campaign progress in the HISTORY."
                };
                setNewCampaignStart(data);
            } catch (e) {
                try {
                    requestStartNewCampaing.body.ifRetry = true;
                    const data = await API.post("gcAPI", `/post-newCampaign`, requestStartNewCampaing);
                    console.log(data);
                    if (data.state) {
                        updateBalance(balance - budget);
                        document.getElementById("postUrl").value = '';
                        document.getElementById("budget-stepper").value = 5;
                        setBudget(5);
                    }
                    if (data.response.indexOf('Not enough STARS left. Please go to TASKS to earn STARS') >= 0) { } else {
                        data.response = "Your request was processed successfully! Check campaign progress in the HISTORY."
                    };
                    setNewCampaignStart(data);
                } catch (error) {
                    try {
                        requestStartNewCampaing.body.ifRetry = true;
                        const data = await API.post("gcAPI", `/post-newCampaign`, requestStartNewCampaing);
                        console.log(data);
                        if (data.state) {
                            updateBalance(balance - budget);
                            document.getElementById("postUrl").value = '';
                            document.getElementById("budget-stepper").value = 5;
                            setBudget(5);
                        }
                        if (data.response.indexOf('Not enough STARS left. Please go to TASKS to earn STARS') >= 0) { } else {
                            data.response = "Your request was processed successfully! Check campaign progress in the HISTORY."
                        };
                        setNewCampaignStart(data);
                    } catch (error) {
                        console.log(e.message);
                        setNewCampaignStart({
                            loading: false,
                            state: false,
                            response: "Your request need few more minutes to process. Please check HISTORY to see if camaign created and reload the page to check for balance change."
                        });
                    }
                }
            };
        };
    };

    return (
        <>
            <View align="center">
                <View maxWidth="1000px" align="left">
                    <Heading level={3} fontWeight={'bold'} textAlign="center" color={"#414c5a"}>
                        3 simple steps to start<br />
                        LinkedIn engagement campaign
                    </Heading>

                    <br />
                    <b>Step 1.</b> Paste your post URL here:
                    <TextField
                        id="postUrl"
                        hasError={urlHasError}
                        errorMessage="please fill in correct LinkedIn post URL"
                        placeholder="e.g. https://www.linkedin.com/posts/LongString ..."
                    />
                    <br />

                    <b>Step 2.</b> Choose activities:
                    <Flex justifyContent="center">
                        <StepperField
                            max={balance ? balance : 0}
                            min={5}
                            step={5}
                            onStepChange={handleOnStepChange}
                            id="budget-stepper"
                        />
                        <SelectField id="activityType">
                            <option value="FREE">Likes and Comments</option>
                        </SelectField>
                    </Flex>
                    <br />

                    <b>Step 3.</b> Confirm and start: <br />
                    <Heading level={3} fontWeight={'bold'} textAlign="center">
                        <br />
                        {budget}<Rating
                            value={1}
                            maxValue={1}
                            fillColor="hsl(51, 100%, 50%)"
                        />
                        <br />
                        <br />
                        {(balance - budget >= 0) ? <Button variation={newCampaignStart.loading ? "disabled" : "primary"} type="submit" onClick={() => startNewCampaing()}>{newCampaignStart.loading ? <><Loader />&nbsp;START CAMPAIGN</> : "START CAMPAIGN"}</Button> : <></>}
                        {(balance - budget < 0) ? <Button variation="disabled" >NOT ENOUGH STARS</Button> : <></>}
                        <br />
                    </Heading>
                    {!balance ? <Button variation="primary" type="submit" onClick={() => navigate("/tasks")}>Go to <b>TASKS</b> to earn <b>STARS</b></Button> : <></>}
                    <View align="center"><br />Check video guide <Link href={!!balance ? "https://youtu.be/uYyI9pYpQnM" : "https://youtu.be/ygOvsL7jlcY"} target="_blank">here</Link></View>
                    
                </View>
            </View>


            <Modal
                show={showModal}
                setShow={setShowModal}
            >
                <ModalHeader>
                    <h2>Your request result:</h2>
                    <p>It may take up to couple of minutes to process your request.</p>
                </ModalHeader>
                <ModalBody>
                    {newCampaignStart.loading ?
                        <><View align="center"><Countdown width="5rem" height="5rem" /></View></>
                        :
                        <p style={{ textAlign: 'justify' }}>
                            {newCampaignStart.response}
                        </p>
                    }
                </ModalBody>
                <ModalFooter>
                    <View align="right">
                        <Button onClick={() => setShowModal(false)}>
                            Close
                        </Button>
                    </View>
                </ModalFooter>
            </Modal>

        </>
    )
}